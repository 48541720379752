export const HOME_ROUTE = '/';
export const MENU_ROUTE = '/menu';
export const MENU_ITEMS_ROUTE = '/menu/items';
export const MODIFIERS_ROUTE = '/menu/modifiers';
export const RULES_ROUTE = '/menu/rules';
export const LOGIN_ROUTE = '/login';
export const ONI_ROUTE = '/oni';
export const LOGOUT_ROUTE = '/logout';
export const LOCATIONS_ROUTE = '/location';
export const BRANDS_ROUTE = '/brand';
export const BUSINESS_ROUTE = '/business';
export const BRANCH_ROUTE = '/branch';
export const BRANDS_CREATE_ROUTE = '/brand/create';
export const PROFILE_ROUTE = '/profile';
export const PROFILE_PASSWORD_CHANGE_ROUTE = '/profile/password/change';
export const PROVIDERS_ROUTE = '/provider';
export const ANALYTICS_ROUTE = '/analytics';
export const USERS_ROUTE = '/user';
export const ADMINS_ROUTE = '/user/admins';
export const PERMISSION_ROUTE = '/permission';
export const ROLE_ROUTE = '/role';
export const ACTIVITIES_ROUTE = '/activities';
export const WEBHOOKS_ROUTE = '/webhooks';
export const INTEGRATION_STATUS_ROUTE = '/integration-status';
export const INTEGRATION_ROUTE = '/integration';
export const PROVIDER_ROUTE = '/provider';
export const PROVIDER_INTEGRATION_ROUTE = '/provider-integration';
export const PRINTER_SETTINGS_ROUTE = '/printer-settings';
export const MANUAL_ORDER_ROUTE = '/add-order';
export const INTEGRATIONS_ROUTE = '/integration';
export const FORGOT_PASSWORD_ROUTE = '/forgot-password';
export const ROUTE_NOT_FOUND_404_ROUTE = '/404';
export const SIGNUP_ROUTE = '/signup';
export const PASSWORD_RESET_ROUTE = '/password/reset';
export const MENU_V2_ROUTE = '/menu-v2';
export const LANG_EXCEL_TO_JSON_ROUTE = '/lang-excel-to-json';
export const QR_GENERATOR = '/generate-qr';
export const LINKS_IN_BIO_ROUTE = '/links';
export const PROMO_ROUTE = '/promo';
export const PROMO_ROUTE_ADD = '/promo/add';
export const PROMO_ROUTE_EDIT = '/promo/edit';
export const LOYALTY_ROUTE = '/loyalty';

export const WEBSHOP_ROUTE = '/webshop';
export const WEBSHOP_BRAND_CUSTOMIZATION_ROUTE = '/webshop/brand-customization';
export const WEBSHOP_BRAND_CUSTOMIZATION_ROUTE_V2 =
  '/v2/webshop/brand-customization';
export const WEBSHOP_FOODHALL_ROUTE = '/webshop/foodhall';
export const WEBSHOP_PAYMENT_SETTINGS_ROUTE = '/webshop/payment-settings';
export const WEBSHOP_NOTICE_ROUTE = '/webshop/notice';

export const RESERVATION_ROUTE = '/reservation';
export const RESERVATION_SETTINGS_ROUTE = '/reservation/settings';
export const RESERVATION_LOCATIONS_ROUTE = '/reservation/locations';
export const ADD_RESERVATION_ROUTE = '/reservation/add-new';

export const WHITELABEL_ROUTE = '/whitelabel';

export const IMAGE_MANAGER_ROUTE = '/image-manager';

export const PAYMENT_INTEGRATION_ROUTE = '/payment-integration';
export const STRIPE_PAYMENT_INTEGRATION_ROUTE = '/payment-integration/stripe';

export const RATING_REVIEW_INTEGRATION_ROUTE = '/rating-review-integration';

export const RATING_INTEGRATION_FACEBOOK_REDIRECT_ROUTE =
  '/rating-review-integration/facebook-redirect';

export const AGGREGATORS_TIKTOK_REDIRECT_ROUTE = '/provider/tiktok-redirect';

export const LIVE_MONITOR_PAUSED_STORES_ROUTE = '/live-monitor/paused-stores';
export const LIVE_MONITOR_INTEGRATION_STATUS_ROUTE =
  '/live-monitor/integration-status-list';
export const LIVE_MONITOR_MENU_OOS_LIST_ROUTE =
  '/live-monitor/oos-activity/menu';
export const LIVE_MONITOR_CATEGORY_OOS_LIST_ROUTE =
  '/live-monitor/oos-activity/category';
export const LIVE_MONITOR_ITEM_OOS_LIST_ROUTE =
  '/live-monitor/oos-activity/item';
export const LIVE_MONITOR_ROUTE = '/live-monitor';

export const CAMPAIGN_ROUTE = '/campaign';
export const CAMPAIGN_ROUTE_ADD = '/campaign/add';

export const FEATURE_ACCESS_ROUTE = '/feature-access';
export const ADD_ORDER_CONFIG_PAYMENT_METHOD =
  '/add-order-config/payment-methods';
export const ADD_ORDER_CONFIG_SETTINGS = '/add-order-config/settings';

export const MENU_PULLING_ROUTE = '/menu-pulling';

export const CUSTOMER_LIST_ROUTE = '/customers';
export const CUSTOMER_DETAIL_ROUTE = '/customers/:id';
